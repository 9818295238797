<script setup>
import { ref, defineEmits, defineProps, onMounted, onUnmounted, computed} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore} from 'vuex';
import SnackbarComposables from '../../composables/SnackbarComposables';
import ProductService from '../../services/ProductService';
/* Emits */
const emits = defineEmits(['closeModal', 'PidUpdated']);
/* Props */
const props = defineProps({
    product:{required:true}
});
/* Data */
const { t } = useI18n();
const newPid = ref('');
const store = useStore();
const submitStatus = ref('void');
const { addSnackbar } = SnackbarComposables();
const {updatePidProduct} = ProductService();
/* Computed */
const setSubmitContent = computed( () => 
{
    switch(submitStatus.value)
    {
        case 'void': default:
            return t('actions.save', store.state.locale);
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
    }
});
const setSubmitClasses = computed( () =>
{
    switch(submitStatus.value)
    {
        case 'void': case 'loading': default:
            return 'bg-blue-500 hover:bg-blue-300';
        case 'error':
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
    }
});
/* Methods */
const updatePid = async () =>
{
    if(!newPid.value) return addSnackbar('error', t('updatePidModal.noPidError', store.state.locale));

    submitStatus.value = 'loading';
    setTimeout( () => {if(submitStatus.value == 'loading') submitStatus.value = 'void';}, 5000);
    const res = await updatePidProduct(props.product.id, {new_pid: newPid.value});
    if(res.status)
    {
        /* 
        
        if(res)
        {
            if(res.status === 200) 
            {
                addSnackbar('success', t('updatePidModal.updatedMessage', store.state.locale));
                router.push(`/${store.state.locale}/dashboard`);
                return 200;
            }
            else 
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
            */
        if(res.status == 'success')
        {
            addSnackbar('success', t('updatePidModal.updatedMessage', store.state.locale));
            submitStatus.value = 'void';
            return emits('PidUpdated', newPid.value);
        }
        else if(res.status == 'error')
        {
            if(res.message)
            {
                if(res.message == 'pid_already_stored') addSnackbar('error', t('updatePidModal.PidAlreadyStored', store.state.locale));
            }
        }
    }
    submitStatus.value = 'void';
}
/* Hooks */
onMounted( () => document.body.classList.add('hoverflow-hidden'));
onUnmounted( () => document.body.classList.remove('hoverflow-hidden'));
</script>
<template>
    <div
        @click.prevent="emits('closeModal')" 
        class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div
                @click.stop 
                class="bg-white dark:bg-gray-900 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3 dark:text-gray-400">
                <div class="flex items-center justify-between">
                    <h2 class="text-xl flex items-center space-x-1">
                        <span>{{ t('updatePidModal.title', store.state.locale) }}</span> 
                    </h2>
                    <button 
                        :title="t('actions.close', store.state.locale)"
                        @click.prevent="emits('closeModal')"
                        class="bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
               
                <div class="flex flex-col space-y-4 min-h-[10rem] overflow-y-auto overscroll-contain text-sm">
                    <div class="flex items-center space-x-1 text-lg">
                        <span>{{ t('updateQuantityModal.product', store.state.locale ) }}:</span>
                        <span class="font-semibold">{{ props.product.name }}</span>
                    </div>
                    <div class="flex items-center space-x-1">
                        <span>{{ t('updatePidModal.formulator', store.state.locale ) }}:</span>
                        <span class="font-semibold">{{ props.product.formulator ? props.product.formulator.name : (props.product.formulator_name ? props.product.formulator_name : '-') }}</span>
                    </div>
                    <div class="">
                        <label for="newPid" class="inline-block mb-1">
                            <span>PID:</span>
                            <span class="text-light-green-500 dark:text-blue-400">*</span>
                        </label>
                        <input 
                            id="newPid" v-model="newPid" required name="newPid"
                            placeholder="PID"
                            type="text"
                            class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"/>
                        <span
                            v-if="store.state.errors.errors.new_pid" 
                            class="text-sm text-red-500" >
                            {{ store.state.errors.errors.new_pid[0] }}
                        </span>
                    </div>
                </div>
                <div class="flex items-center justify-end w-full">
                    <button 
                        :title="t('actions.save', store.state.locale)"
                        @click.prevent="updatePid" 
                        class="flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                        :disabled="submitStatus !== 'void'"
                        :class="setSubmitClasses"
                        v-html="setSubmitContent">
                    </button> 
                </div>
            </div>
        </div>
    </div>
</template>
